<template>
  <Default>
    <template v-slot:breadcrumb>
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>Anasayfa</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <template v-slot:content>
      <div class="content">

        <a-empty
            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
            :image-style="{height: '60px'}">
          <template #description>
              <span>
                Burada henüz bir şey yok .s
              </span>
          </template>
        </a-empty>

        <!--        <a-row :gutter="[16,16]">-->
        <!--          <a-col :span="8">-->
        <!--            <a-row :gutter="16">-->
        <!--              <a-col :span="12">-->
        <!--                <a-card>-->
        <!--                  <a-statistic-->
        <!--                      title="Feedback"-->
        <!--                      :value="11.28"-->
        <!--                      :precision="2"-->
        <!--                      suffix="%"-->
        <!--                      :value-style="{ color: '#3f8600' }"-->
        <!--                      style="margin-right: 50px"-->
        <!--                  >-->
        <!--                    <template #prefix>-->
        <!--                      <arrow-up-outlined />-->
        <!--                    </template>-->
        <!--                  </a-statistic>-->
        <!--                </a-card>-->
        <!--              </a-col>-->
        <!--              <a-col :span="12">-->
        <!--                <a-card>-->
        <!--                  <a-statistic-->
        <!--                      title="Idle"-->
        <!--                      :value="9.3"-->
        <!--                      :precision="2"-->
        <!--                      suffix="%"-->
        <!--                      class="demo-class"-->
        <!--                      :value-style="{ color: '#cf1322' }"-->
        <!--                  >-->
        <!--                    <template #prefix>-->
        <!--                      <arrow-down-outlined />-->
        <!--                    </template>-->
        <!--                  </a-statistic>-->
        <!--                </a-card>-->
        <!--              </a-col>-->
        <!--            </a-row>-->
        <!--          </a-col>-->

        <!--          <a-col :span="8">-->
        <!--            <a-row :gutter="[8,8]">-->
        <!--              <a-col :span="12">-->
        <!--                <a-statistic title="Active Users" :value="112893" style="margin-right: 50px"/>-->
        <!--              </a-col>-->
        <!--              <a-col :span="12">-->
        <!--                <a-statistic title="Account Balance (CNY)" :precision="2" :value="112893"/>-->
        <!--              </a-col>-->
        <!--            </a-row>-->
        <!--          </a-col>-->

        <!--          <a-col :span="8">-->
        <!--            <a-row :gutter="16">-->
        <!--              <a-col :span="12">-->
        <!--                <a-statistic title="Feedback" :value="1128" style="margin-right: 50px">-->
        <!--                  <template #suffix>-->
        <!--                    <like-outlined />-->
        <!--                  </template>-->
        <!--                </a-statistic>-->
        <!--              </a-col>-->
        <!--              <a-col :span="12">-->
        <!--                <a-statistic title="Unmerged" :value="93" class="demo-class">-->
        <!--                  <template #suffix>-->
        <!--                    <span>/ 100</span>-->
        <!--                  </template>-->
        <!--                </a-statistic>-->
        <!--              </a-col>-->
        <!--            </a-row>-->
        <!--          </a-col>-->
        <!--        </a-row>-->

      </div>
    </template>
    <template v-slot:mTitle>Ana Sayfa | Bill</template>
  </Default>
</template>

<script>
import Default from "@/components/_layouts/Default";
import {ArrowUpOutlined, ArrowDownOutlined, LikeOutlined} from '@ant-design/icons-vue';

export default {
  name: "Dashboard",
  components: {
    Default,
    ArrowUpOutlined, ArrowDownOutlined, LikeOutlined
  }
};
</script>

<style scoped>
.content {
  padding: 24px;
  background: #fff;
  min-height: 360px;
}
</style>
